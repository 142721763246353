import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Admin.scss';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Admin = () => {
  const [data, setData] = useState(null);
  const [form, setForm] = useState({ blocks: [], paymentInfo: '' });
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/auth');
      return;
    }

    axios.get('/api/content', {
      headers: {
        'x-access-token': token,
      },
    }).then((response) => {
      setData(response.data);
      setForm(response.data);
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        alert('Ошибка авторизации');
        navigate('/');
      } else {
        console.error('Ошибка загрузки данных:', error);
      }
    });
  }, [navigate]);

  const handleChange = (name, value) => {
    if (name.startsWith('blockTitle')) {
      const index = name.split('blockTitle')[1];
      const updatedBlocks = [...form.blocks];
      updatedBlocks[index].title = value;
      setForm({ ...form, blocks: updatedBlocks });
    } else if (name.startsWith('blockDescription')) {
      const index = name.split('blockDescription')[1];
      const updatedBlocks = [...form.blocks];
      updatedBlocks[index].description = value;
      setForm({ ...form, blocks: updatedBlocks });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleFileChange = (e, banner) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('bannerType', banner);

    const token = localStorage.getItem('token');
    if (!token) {
      alert('Вы не авторизованы');
      navigate('/');
      return;
    }

    axios.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-access-token': token,
      },
    }).then((response) => {
      setForm({ ...form, [banner]: response.data.url });
    }).catch((error) => {
      console.error('Ошибка загрузки файла:', error);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    if (!token) {
      alert('Вы не авторизованы');
      navigate('/');
      return;
    }

    axios.post('/api/content', form, {
      headers: {
        'x-access-token': token,
      },
    }).then((response) => {
      setData(response.data);
      alert('Изменения сохранены!');
    }).catch((error) => {
      console.error('Ошибка обновления контента:', error);
    });
  };

  const handlePasswordChange = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Вы не авторизованы');
      navigate('/');
      return;
    }

    axios.post('/api/change-password', { newPassword }, {
      headers: {
        'x-access-token': token,
      },
    }).then((response) => {
      alert('Пароль успешно изменен!');
      setNewPassword('');
    }).catch((error) => {
      console.error('Ошибка смены пароля:', error);
    });
  };

  const addBlock = () => {
    setForm({
      ...form,
      blocks: [...form.blocks, { title: '', description: '' }],
    });
  };

  if (!data) return <p>Loading...</p>;

  return (
    <div className='Admin'>
      <form onSubmit={handleSubmit}>
        <div className='top'>
          <h2>Админ панель</h2>
          <button type="submit" className='save'>Сохранить</button>
        </div>
        <div className='line'></div>
        <div className='row'>
          <h2>Смена пароля:</h2>
          <input 
            type="password" 
            placeholder="Новый пароль" 
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)} 
          />
          <button type="button" onClick={handlePasswordChange}>Сменить пароль</button>
        </div>
        <div className='line'></div>
        <div className='banners'>
          <h2><span>Б</span>аннер 1:</h2>
          <input type="file" onChange={(e) => handleFileChange(e, 'banner1')} />
          <h2><span>Б</span>аннер 2:</h2>
          <input type="file" onChange={(e) => handleFileChange(e, 'banner2')} />
        </div>
        <h2><span>З</span>аголовок сайта:</h2>
        <ReactQuill 
          theme="snow" 
          value={form.mainTitle} 
          onChange={(value) => handleChange('mainTitle', value)} 
        />
        <h2><span>О</span>писание сайта:</h2>
        <ReactQuill 
          theme="snow" 
          value={form.subTitle} 
          onChange={(value) => handleChange('subTitle', value)} 
        />
        <div className='row'>
          <h2><span>Б</span>локи</h2>
          <button type="button" onClick={addBlock}>Добавить блок</button>
        </div>
        {form.blocks.map((block, index) => (
          <div key={index} className='blocks'>
            <h2>Заголовок блока {index + 1}:</h2>
            <ReactQuill 
              theme="snow" 
              value={block.title} 
              onChange={(value) => handleChange(`blockTitle${index}`, value)} 
            />
            <h2>Описание блока {index + 1}:</h2>
            <ReactQuill 
              theme="snow" 
              value={block.description} 
              onChange={(value) => handleChange(`blockDescription${index}`, value)} 
            />
          </div>
        ))}
        <div className='paymentInfo'>
          <h2><span>И</span>нформация об оплате:</h2>
          <ReactQuill 
            theme="snow" 
            value={form.paymentInfo} 
            onChange={(value) => handleChange('paymentInfo', value)} 
          />
        </div>
      </form>
    </div>
  );
};

export default Admin;
