import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Banner from './Banner';
import Block from './Block';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import './HomePage.scss';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';

const HomePage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios.get('/api/content')
      .then((response) => {
        const content = response.data;
        if (!Array.isArray(content.blocks)) {
          content.blocks = [];
        }
        setData(content);
      })
      .catch((error) => console.error('Error fetching content:', error));
  }, []);

  if (!data) return <p>Loading...</p>;

  return (
    <div className='HomePage'>
      <div className='banner_content'>
        <Banner imageUrl={'' + data.banner1} />
      </div>
      <div className='content'>
        <h1>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="9" width="2" height="20" fill="#636363"/>
            <rect y="11" width="2" height="20" transform="rotate(-90 0 11)" fill="#636363"/>
            <rect x="2" y="3.41431" width="2" height="20" transform="rotate(-45 2 3.41431)" fill="#636363"/>
            <rect x="16.1422" y="2" width="2" height="20" transform="rotate(45 16.1422 2)" fill="#636363"/>
          </svg>
          <span dangerouslySetInnerHTML={{__html: data.mainTitle}}></span>
        </h1>
        <h2 dangerouslySetInnerHTML={{__html: data.subTitle}}></h2>
        {/* <div className="blocks">
          {data.blocks.map((block, index) => (
            <Block key={index} title={block.title} description={block.description} />
          ))}
        </div> */}
        <Accordion defaultActiveKey={0} style={{width: "100%"}}>
          {data.blocks.map((block, index) => (
            <AccordionItem key={index} eventKey={index} >
              <Accordion.Header><span dangerouslySetInnerHTML={{__html: block.title}}></span></Accordion.Header>
              <Accordion.Body>
              <p dangerouslySetInnerHTML={{__html: block.description}}></p>
              </Accordion.Body>
            </AccordionItem>
          ))}
        </Accordion><br/>
        <div className="paymentInfo">
          <h2>Информация об оплате:</h2><br/>
          <p>Набираем <b>курьеров</b> по <b>Тверской</b> и <b>Тульской</b> области. Высокая оплата, премии, своевременные мк.<br/>Предлагаем франшизу: 80% вам, 20% нам с учетом автоматических скидок.<br/>Сетка оплат за клады:</p>
          {/* <p dangerouslySetInnerHTML={{__html: data.paymentInfo}}></p> */}
        </div>
        <Table responsive style={{width: "100%"}}>
          <thead>
            <tr>
              <th>Продукт</th>
              <th>0.25г</th>
              <th>0.5г</th>
              <th>1г</th>
              <th>2г</th>
              <th>3г</th>
              <th>5г</th>
              <th>10г</th>
              <th>20г</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Alpha-Pvp</th>
              <th>500 руб</th>
              <th>600 руб</th>
              <th>750 руб</th>
              <th>900 руб</th>
              <th>1000 руб</th>
              <th>1200 руб</th>
              <th>1500 руб</th>
              <th>-</th>
            </tr>
            <tr>
              <th>Амфетамин VHQ</th>
              <th>-</th>
              <th>600 руб</th>
              <th>700 руб</th>
              <th>800 руб</th>
              <th>900 руб</th>
              <th>1000 руб</th>
              <th>1100 руб</th>
              <th>2500 руб</th>
            </tr>
            <tr>
              <th>Мефедрон кристаллы</th>
              <th>-</th>
              <th>650 руб</th>
              <th>750 руб</th>
              <th>850 руб</th>
              <th>950 руб</th>
              <th>1100 руб</th>
              <th>1300 руб</th>
              <th>-</th>
            </tr>
            <tr>
              <th>Метадон</th>
              <th>600 руб</th>
              <th>850 руб</th>
              <th>1000 руб</th>
              <th>1200 руб</th>
              <th>1400 руб</th>
              <th>1800 руб</th>
              <th>2200 руб</th>
              <th>-</th>
            </tr>
            <tr>
              <th>Метамфетамин</th>
              <th>600 руб</th>
              <th>700 руб</th>
              <th>900 руб</th>
              <th>-</th>
              <th>-</th>
              <th>-</th>
              <th>-</th>
              <th>-</th>
            </tr>
            <tr>
              <th>Гашиш</th>
              <th>-</th>
              <th>600 руб</th>
              <th>700 руб</th>
              <th>800 руб</th>
              <th>1000 руб</th>
              <th>1200 руб</th>
              <th>1400 руб</th>
              <th>-</th>
            </tr>
            <tr>
              <th>Шишки</th>
              <th>-</th>
              <th>-</th>
              <th>600 руб</th>
              <th>750 руб</th>
              <th>850 руб</th>
              <th>1000 руб</th>
              <th>1300 руб</th>
              <th>-</th>
            </tr>
            <tr>
              <th>МДМА кристаллы</th>
              <th>-</th>
              <th>600 руб</th>
              <th>700 руб</th>
              <th>800 руб</th>
              <th>900 руб</th>
              <th>-</th>
              <th>-</th>
              <th>-</th>
            </tr>
          </tbody>
        </Table>
        <br/>
        <Table responsive>
          <thead>
            <tr>
              <th>Продукт</th>
              <th>2 шт</th>
              <th>3 шт</th>
              <th>4 шт</th>
              <th>5 шт</th>
              <th>6 шт</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Таблетки МДМА</th>
              <th>600 руб</th>
              <th>-</th>
              <th>700 руб</th>
              <th>800 руб</th>
              <th>1000 руб</th>
            </tr>
            <tr>
              <th>Марки ЛСД</th>
              <th>750 руб</th>
              <th>800 руб</th>
              <th>850 руб</th>
              <th>-</th>
              <th>-</th>
            </tr>
          </tbody>
        </Table>
        <div className='paymentInfo'>
          <p>Оплата за клады в Тверской области +100 руб в зависимости от товара. Бонусы: Более 100 загруженных кладов за неделю — 5.000 Более 200 загруженных кладов за неделю — 10.000 Трудолюбивые кладмены смогут получать от 100 000 в неделю + премии.<br/>Обращаться через тикеты на нашем сайте: LitoShop.cc<br/>Tor - <a href='q3hussnaartr43xm6tqzxrhzhfsu4lov6s5ayeqwy4ovkl646v5sttyd.onion'>q3hussnaartr43xm6tqzxrhzhfsu4lov6s5ayeqwy4ovkl646v5sttyd.onion</a></p>
        </div>
      </div>
      <div className='banner_content'>
        <Banner imageUrl={'' + data.banner2} />
        <p>© 2024 Lito Shop.</p>
      </div>
    </div>
  );
};

export default HomePage;
