import './Login.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await axios.post('/api/login', 
                { username, password }, 
                { headers: { 'Content-Type': 'application/json' } }
            );

            if (response.status === 200) {
                const data = response.data;
                if (data.auth && data.token) {
                    localStorage.setItem('token', data.token);
                    navigate('/admin');
                } else {
                    alert('Ошибка авторизации');
                }
            } else {
                alert('Ошибка авторизации');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                alert('Ошибка авторизации: неверные учетные данные');
            } else {
                alert('Ошибка авторизации');
            }
        }
    };

    return (
        <div className='Login'>
            <div className='form'>
                <input 
                    type="text" 
                    placeholder='Логин' 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                />
                <input 
                    type="password" 
                    placeholder='Пароль' 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
                <button onClick={handleLogin}>Войти</button>
            </div>
        </div>
    );
}
